<template>
  <div>
    <b-form-group label="Банк">
      <b-form-select
        v-model="formData.bank"
        :options="banksList"
      />
    </b-form-group>
    <b-form-group label="Номер первого поручения">
      <b-form-input
        v-model="formData.number"
        type="number"
        step="1"
        min="1"
      />
    </b-form-group>
    <b-form-group label="Сформировать на дату">
      <b-form-input
        v-model="formData.date"
        type="date"
      />
    </b-form-group>
    <b-form-group label="Дата документа">
      <b-form-input
        v-model="formData.doc_date"
        type="date"
      />
    </b-form-group>
    <div class="d-flex justify-content-end">
      <b-button
        variant="light"
        @click="$emit('cancel')"
      >
        Отмена
      </b-button>
      <b-button
        variant="primary"
        class="ml-1"
        @click="onCreateHandler"
      >
        Создать
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BFormSelect,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
  },
  data() {
    return {
      step: 1,
      formData: {
        bank: 'bank.alfa',
        number: 1,
        date: moment()
          .format('YYYY-MM-DD'),
        doc_date: moment()
          .format('YYYY-MM-DD'),
      },
      banksList: [
        {
          value: 'bank.alfa',
          text: 'Альфабанк',
        },
        {
          value: 'bank.sber',
          text: 'Сбербанк',
        },
      ],
    }
  },
  methods: {
    onCreateHandler() {
      this.$axios.post('/operations/bank-packages', this.formData)
    },
  },
}
</script>
