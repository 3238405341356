<template>
  <div>
    <v-table
      path="/operations/bank-packages"
      :fields="packageTableFields"
      @create="onPackageCreateHandler"
    >
      <template #cell(actions)="{ item }">
        <v-table-actions-column
          :del="false"
          :edit="false"
        />
      </template>
    </v-table>
    <b-modal
      ref="package-modal"
      title="Создать пакет поручений"
      hide-footer
    >
      <package-create-form
        @cancel="$refs['package-modal'].hide()"
      />
    </b-modal>
  </div>
</template>

<script>
import VTable, {
  VTableActionsColumn,
} from '@/views/components/vtable'
import {
  BModal,
} from 'bootstrap-vue'
import PackageCreateForm from '@/views/operations/components/PackageCreateForm.vue'
import {
  packageTableFields,
} from './packages_use'

export default {
  components: {
    PackageCreateForm,
    BModal,
    VTable,
    VTableActionsColumn,
  },
  data() {
    return {
      packageTableFields,
    }
  },
  methods: {
    onPackageCreateHandler() {
      this.$refs['package-modal'].show()
    },
  },
}
</script>
